import React from "react";
import Layout from "../styles/layout";
import { Typography, Container } from "@mui/material";
import Seo from "../components/seo";

export default function PrivacyPolicy({ props, location }) {
  return (
    <Layout location={location}>
      <Seo
        title="Privacy policy"
        description="This site does not store any of your personal data."
      />
      <Container>
        <Typography variant="h1">Privacy policy</Typography>
        <Typography>
          <p>This site does not store any of your personal data.</p>
          <p>
            This site may contain links to other sites that are not operated by
            us. If you click on a third party link, you will be directed to that
            third party’s site. We advise you to review the Privacy Policy of
            every site you visit.
          </p>
          <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us by email tanaschita@gmail.com
          </p>
          <p>Last updated on 15. July 2022.</p>
        </Typography>
      </Container>
    </Layout>
  );
}
